import React, { useState, useContext, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faSearch,  faBars } from '@fortawesome/free-solid-svg-icons';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import AuthModal from './Adimn/AuthModal'; 
import { AuthContext } from '../Context/AuthContext';
import UserAccountModal from './Adimn/UserAccountModal';
import logo from '../assets/img/logo.png';
import ReactCountryFlag from "react-country-flag"
import {  faFacebook,faInstagram,faTiktok,faYoutube } from '@fortawesome/free-brands-svg-icons';

const Navbar = () => {
  const { user, logout } = useContext(AuthContext) || {};
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  
  const navbarRef = useRef(null); 
  const dropdownRef = useRef(null); 

  const cln = "block py-2 l m-2 text-[#FD5F20] hover:opacity-60 lg:border-none cursor-pointer hover:text-[#FD5F20] hover:opacity-60 backdrop-blur-sm shadow-lg";

  const toggleNavbar = () => setIsOpen(prev => !prev);
  const handleLogout = () => {
    logout();
    setIsDropdownOpen(false);
  };

  const handleModalClose = () => setIsModalOpen(false);
  const toggleDropdown = () => setIsDropdownOpen(prev => !prev);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef, navbarRef]);

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
<nav
  className="fixed top-4 z-50 w-full sm:w-full md:w-3/4 lg:w-3/4 mx-auto flex shadow-lg  items-center bg-white rounded-lg shadow-[0px_4px_15px_rgba(0,0,0,0.1)] p-2 md:p-4"
  ref={navbarRef}
>      {/* bg-gradient-to-r from-[#FD5F20] via-[#FF8C00] to-[#FD5F20] shadow-[0px_4px_15px_rgba(0,0,0,0.6)] */}
      <div className="container mx-auto max-w-6xl px-4">
        <div className="flex justify-between items-center text-lg font-semibold ">
          <ScrollLink to="hero" smooth={true} duration={500}>
            <img className='h-16 cursor-pointer ' src={logo} style={{ borderRadius: '20%' }} alt="Logo" />
          </ScrollLink>

          <div className="hidden md:flex md:justify-center md:flex-grow  ">
              <ul className="flex space-x-4 text-[#FD5F20] text-md">
                
              <li><RouterLink to="/ourStory" className="  hover:text-[#FD5F20] hover:opacity-60 cursor-pointer " onClick={handleLinkClick}>Our Story</RouterLink></li>
              <li><RouterLink to="/Service" className="hover:text-[#FD5F20] hover:opacity-60 cursor-pointer" onClick={handleLinkClick}>Services</RouterLink></li>
              <li><RouterLink to="/?section=ContactForm" className="hover:text-[#FD5F20] hover:opacity-60 cursor-pointer" onClick={handleLinkClick}>Contact</RouterLink></li>
              <li><RouterLink to="/blog" className="hover:text-[#FD5F20] hover:opacity-60 cursor-pointer" onClick={handleLinkClick}>Blog</RouterLink></li>
              <li><RouterLink to="/Connect" className="hover:text-[#FD5F20] hover:opacity-60 cursor-pointer" onClick={handleLinkClick}>Connect</RouterLink></li>
              
              <li>
              
                <ReactCountryFlag
                countryCode="US"
                svg
           
                title="EN"
            />          
         <a href="#" className="p-2 hover:text-[#FD5F20] hover:opacity-60">EN</a> 
              </li>
              <li className='hover:text-[#FD5F20] hover:opacity-60 cursor-pointer'><FontAwesomeIcon icon={faSearch}/> </li>
              <li>
                <ul className='flex space-x-2 text-[#FD5F20] text-xl'>
                 
              <li className='hover:text-[#FD5F20] hover:opacity-60 cursor-pointer'><a href='https://www.facebook.com/profile.php?id=61561831803941'><FontAwesomeIcon icon={faFacebook}/></a> </li>
              <li className='hover:text-[#FD5F20] hover:opacity-60 cursor-pointer'><a href='https://www.instagram.com/atocreative_et'><FontAwesomeIcon icon={faInstagram}/></a> </li>
              <li className='hover:text-[#FD5F20] hover:opacity-60 cursor-pointer'><a href='https://www.tiktok.com/@atocreative_et'><FontAwesomeIcon icon={faTiktok}/> </a></li>
              <li className='hover:text-[#FD5F20] hover:opacity-60 cursor-pointer'><a href='https://youtube.com/@atocreative_et'><FontAwesomeIcon icon={faYoutube}/></a> </li>
              
                </ul>
              </li>
             
              


              {user ? (
                <>
                  <li><RouterLink to="/admin" className="hover:text-[#FD5F20] hover:opacity-60 cursor-pointer" onClick={handleLinkClick}>Admin Panel</RouterLink></li>
                  <li className="relative" ref={dropdownRef}>
                    <button onClick={toggleDropdown} className="hover:underline" aria-label="User Details">{user.email}</button>
                    {isDropdownOpen && <UserAccountModal user={user} onLogout={handleLogout} onClose={() => setIsDropdownOpen(false)} />}
                  </li>
                </>
              ) : (
                <button onClick={() => setIsModalOpen(true)} className="hover:underline" aria-label="Login">Login</button>
              )}
            </ul>
          </div>

          <AuthModal isOpen={isModalOpen} onClose={handleModalClose} />

          <div className="md:hidden">
            <button className="text-[#FD5F20] hover:opacity-60 font-bold text-2xl focus:outline-none" onClick={toggleNavbar} aria-label="Toggle Navbar">
              <FontAwesomeIcon icon={faBars} />
            </button>
          </div>
        </div>

        {isOpen && (
          <div className="md:hidden bg-gradient-to-r from-[#FD5F20] via-[#FF8C00] to-[#FD5F20] shadow-lg">
            <ul className="ml-4">
              <li><ScrollLink to="About" smooth={true} duration={500} className={cln} onClick={handleLinkClick}>About Us</ScrollLink></li>
              <li><ScrollLink to="services" smooth={true} duration={500} className={cln} onClick={handleLinkClick}>Services</ScrollLink></li>
              <li><ScrollLink to="ContactForm" smooth={true} duration={500} className={cln} onClick={handleLinkClick}>Contact</ScrollLink></li>
              <li><RouterLink to="/blog" className={cln} onClick={handleLinkClick}>Blog</RouterLink></li>
              {user && <li><RouterLink to="/admin" className={cln} onClick={handleLinkClick}>Admin Panel</RouterLink></li>}
              {user ? (
                <li className="relative" ref={dropdownRef}>
                  <button onClick={toggleDropdown} className={cln} aria-label="User Details">{user.email}</button>
                  {isDropdownOpen && <UserAccountModal user={user} onLogout={handleLogout} onClose={() => setIsDropdownOpen(false)} />}
                </li>
              ) : (
                <li><button onClick={() => setIsModalOpen(true)} className={cln} aria-label="Login">Login</button></li>
              )}
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;

import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { Swiper, SwiperSlide } from 'swiper/react';
import vid from '../../../assets/Video/marketing.mp4';

const videos = [
  { title: 'Video 1', url: vid },
  { title: 'Video 2', url: vid },
  { title: 'Video 3', url: vid },
  { title: 'Video 4', url: vid },
  { title: 'Video 5', url: vid },
  { title: 'Video 6', url: vid },
  { title: 'Video 7', url: vid },
  { title: 'Video 8', url: vid },
  { title: 'Video 9', url: vid },
  // Add more videos as needed
];

const VideoProduction = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');

  const openModal = (url) => {
    setCurrentVideoUrl(url);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentVideoUrl('');
  };

  return (
    <div className="container mx-auto ">
      <h2 className="text-3xl font-bold text-center mb-8">Video Production by Our Agency</h2>

      {/* Video Playlist Grid for the first 6 videos */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 mb-8">
        {videos.slice(0, 6).map((video, index) => (
          <div
            key={index}
            className="bg-gray-800 p-4 rounded-lg shadow-lg hover:scale-105 transition-transform cursor-pointer"
            onClick={() => openModal(video.url)}
          >
    
            <div className="relative pb-[56.25%]">
              <video
                className="absolute top-0 left-0 w-full h-full rounded-lg"
                src={video.url}
                title={video.title}
                controls
              />
            </div>
          </div>
        ))}
      </div>

      {/* Carousel for additional videos */}
      <div className="mb-8">
        <h3 className="text-2xl text-center font-semibold mb-4">More Videos</h3>
        <Swiper
          spaceBetween={16}
          slidesPerView={3}
          breakpoints={{
            640: { slidesPerView: 1 },
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
          }}
          loop
        >
          {videos.slice(6).map((video, index) => (
            <SwiperSlide key={index}>
              <div
                className="bg-gray-800 p-4 rounded-lg shadow-lg hover:scale-105 transition-transform cursor-pointer"
                onClick={() => openModal(video.url)}
              >
                
                <div className="relative pb-[56.25%]">
                  <video
                    className="absolute top-0 left-0 w-full h-full rounded-lg"
                    src={video.url}
                    title={video.title}
                    controls
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* Modal for Video Playback */}
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Video Modal"
        className="modal-content"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <div className="relative w-full h-full">
          <button
            onClick={closeModal}
            className="absolute top-4 right-4 text-white text-2xl font-bold"
          >
            X
          </button>
          <video
            className="w-full h-full"
            src={currentVideoUrl}
            title="Video Player"
            controls
            autoPlay
          />
        </div>
      </ReactModal>
    </div>
  );
};

export default VideoProduction;

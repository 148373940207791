import React from 'react';
import mainImage from '../../assets/img/computer.png'; // Replace with actual image path
import thumbnail1 from '../../assets/img/Mask group (2).png';
import thumbnail2 from '../../assets/img/Mask group (3).png';
import thumbnail3 from '../../assets/img/Mask group (4).png';
import thumbnail4 from '../../assets/img/Mask group (5).png';

const PlaylistPage = () => {
    const playlistItems = [
        { id: 1, image: thumbnail1, title: "Learn from My Mistakes: 7 Digital Course Pitfalls to Skip", description: "Discover the top digital course pitfalls to avoid from experienced creator Amy Porterfield." },
        { id: 2, image: thumbnail2, title: "Learn from My Mistakes: 7 Digital Course Pitfalls to Skip", description: "Discover the top digital course pitfalls to avoid from experienced creator Amy Porterfield." },
        { id: 3, image: thumbnail3, title: "Learn from My Mistakes: 7 Digital Course Pitfalls to Skip", description: "Discover the top digital course pitfalls to avoid from experienced creator Amy Porterfield." },
        { id: 4, image: thumbnail4, title: "Learn from My Mistakes: 7 Digital Course Pitfalls to Skip", description: "Discover the top digital course pitfalls to avoid from experienced creator Amy Porterfield." },
        { id: 5, image: thumbnail4, title: "Learn from My Mistakes: 7 Digital Course Pitfalls to Skip", description: "Discover the top digital course pitfalls to avoid from experienced creator Amy Porterfield." },
    ];

    return (
        <div className="flex flex-col md:flex-row p-4 h-screen overflow-hidden">
            {/* Main Image Section */}
            <div className="relative flex-1 md:w-3/5 h-80 p-2 sm:h-96 sm:p-4">
                <img
                    src={mainImage}
                    alt="Main content"
                    className="w-full h-full object-cover rounded-lg"
                />
                
                {/* Text Overlay */}
                <div className="absolute bottom-0 left-0 w-full sm:w-2/3 h-full flex flex-col justify-end items-center p-2 sm:p-4 text-center">
                    <h1 className="text-base sm:text-lg md:text-sm font-bold text-white bg-black bg-opacity-30 p-1 sm:p-2 md:p-4 rounded">
                        What is Digital Marketing?
                    </h1>
                    <p className="mt-2 text-xs sm:text-sm md:text-base text-white bg-black bg-opacity-30 p-1 sm:p-2 md:p-4 rounded">
                        Discover the top digital course pitfalls to avoid from experienced creator Amy Porterfield. 
                        Skip common mistakes and start with confidence!
                    </p>
                </div>
            </div>

            {/* Playlist Section */}
            <div className="md:w-1/4 p-2 overflow-y-auto">
                <h2 className="text-lg font-semibold mb-4">Latest Blogs</h2>
                <div className="space-y-2">
                    {playlistItems.map((item) => (
                        <div key={item.id} className="flex items-start space-x-2">
                            <img
                                src={item.image}
                                alt={item.title}
                                className="w-20 h-20 object-cover rounded-lg"
                            />
                            <div>
                                <h3 className="text-sm sm:text-md font-semibold">{item.title}</h3>
                                <p className="text-gray-600 text-xs sm:text-sm">{item.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PlaylistPage;

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKiwiBird, faFish } from '@fortawesome/free-solid-svg-icons';
import { api } from '../api';
import hs from '../assets/img/iam.png';
import bgr from '../assets/img/bgr2.png';
import marketing from '../assets/Video/marketing.mp4';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const checkEmailExists = async (email) => {
  try {
    const response = await api.get(`contacts/BusinessByEmail/${email}`);
    return response;
  } catch (error) {
    console.error('Error checking email:', error);
    return false;
  }
};

const validationSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Name must be at least 2 characters long').required('Name is required'),
  email: Yup.string().matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Invalid Email').required('Email is required'),
});

const HeroSection = () => {
  const [isVisible, setIsVisible] = useState(false);

  const { register, handleSubmit, formState: { errors, isValid } } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const onSubmit = async (data) => {
    const emailExists = await checkEmailExists(data.email);
    if (emailExists.data && emailExists.data.exists) {
      toast.error('Email is already registered');
      return;
    }
    try {
      const response = await api.post('contacts/Busines_contact', data);
      toast.success(response.data.message);
    } catch (error) {
      toast.error('Error submitting form');
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => setIsVisible(true), 1000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="relative top-12 min-h-screen overflow-hidden">
      <ToastContainer />
      {/* <div className="container mt-8 mx-auto relative z-20 w-full">
        <div className="flex flex-col lg:flex-row items-start justify-between">

          <div className="lg:w-3/5 w-full p-4"> */}



          <div className="container w-full mt-8 mx-auto  px-6 md:px-8  relative z-20  ">
        <div className="flex flex-col-2 lg:flex-row items-start justify-between space-y-6">
          <div className="flex-1 lg:w-2/3 md:w-full w-full mt-0">

            <h1
              className={`text-4xl lg:text-5xl font-extrabold mb-2 sm:mb-4 text-start 
              transition-all duration-700 ease-in-out transform text-center
              ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'} hover:scale-105`}
            >
              <span className="inline-block">
                Join the Movement: Empower Your Startup or SME Today!
              </span>
            </h1>

            <div className="text-center px-4">
              <p className='text-md text-gray-500 font-times text-justify'>
              Ready to take your business to the next level? Our team is dedicated to boosting the online presence of startups and SMEs. Connect with us today and let’s make your digital footprint unforgettable!
              </p>
            </div>

            <div className="pt-4 w-full  flex flex-col justify-center items-center mx-auto">
              <h1 className="text-4xl font-bold shadow-sm drop-shadow-xl font-times text-center">Act Now - Your Future Awaits!</h1>
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 w-full">
                <div className="flex flex-col sm:flex-row">
                  <div className="m-4 w-full">
                    <input
                      type="text"
                      name="name"
                      {...register('name')}
                      className={`border-2 rounded-lg p-3 sm:p-2 w-full focus:outline-none focus:ring-2 shadow-lg ${errors.name ? 'border-red-500 focus:ring-red-500' : isValid ? 'border-green-500 focus:ring-green-500' : 'border-[#FD5F20]'}`}
                      placeholder="Enter your name"
                    />
                    {errors.name && <p className="text-red-500">{errors.name.message}</p>}
                  </div>
                  <div className="m-4 w-full">
                    <input
                      type="email"
                      name="email"
                      {...register('email')}
                      className={`border-2 rounded-lg p-3 sm:p-2 w-full focus:outline-none focus:ring-2 shadow-lg ${errors.email ? 'border-red-500 focus:ring-red-500' : isValid ? 'border-green-500 focus:ring-green-500' : 'border-[#FD5F20]'}`}
                      placeholder="Enter your email"
                    />
                    {errors.email && <p className="text-red-500">{errors.email.message}</p>}
                  </div>
                </div>
                <button
                  type="submit"
                  className="w-full text-lg font-semibold bg-[#FD5F20] text-gray-300 p-3 sm:p-2 rounded-lg hover:bg-[#e04e1c] transition duration-200 shadow-lg"
                >
                  Take Your Business to the Next Level
                </button>
              </form>
            </div>
          </div>

          {/* Right Column - Image and Video Section */}
          <div className="flex-1 lg:w-1/3 w-full p-4 space-y-4">
            <div className="w-full">
              <img
                src={bgr}
                alt="Background Image"
                className="object-cover w-full h-auto"
              />
            </div>
            <div className="flex justify-end">
              <div className="w-screen h-80 relative">
                <video
                  src={marketing}
                  title="Digital Marketing"
                  className="w-full h-80 object-cover rounded-lg border border-red-500"
                  controls
                  autoPlay
                  muted
                  loop
                // style={{ minHeight: '100vh' }} // optional: set a fixed height for fullscreen effect
                />
              </div>


            </div>


          </div>

        </div>
      </div>
    </div>
  );
};

export default HeroSection;


{/* {!isPlaying ? (
                <div onClick={() => setIsPlaying(true)} className="w-full h-full relative">
                  <img
                    src={bgr}
                    alt="YouTube Video Thumbnail"
                    className="w-full h-full rounded-lg object-cover shadow-lg"
                  />
                  <div className="absolute inset-0 flex items-center justify-center">
                    <div className="bg-black bg-opacity-50 p-3 rounded-full">
                      <svg className="w-12 h-12 text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M8 5v14l11-7z" />
                      </svg>
                    </div>
                  </div>
                </div>
              ) : (
                <iframe
                  className="w-full h-full rounded-lg object-cover shadow-lg border border-[#FD5F20]"
                  src={`https://www.youtube.com/watch?v=DoirrCfhjf0&list=PLzILpZM7fd6LD9_8knA8nahAvkBm0kTiG`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )} */}
{/* dark:bg-gray-300 dark:text-gray-400 */ }

{/* Background Image with Opacity */ }
{/* <div
        className="absolute inset-0 bg-cover  object-cover bg-center bg-black opacity-70 backdrop-blur-sm"
        style={{
          backgroundImage: `url(${bg})`,
          opacity:0.4,
       
          zIndex: 10,
        }}
      /> */}
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faComment, faShareAlt } from '@fortawesome/free-solid-svg-icons';
//import { faHeart , faComment , faShareAlt  } from '@fortawesome/free-regular-svg-icons';

const StatsticDashboard = () => {
  // Initialize metrics with dynamic states for like, comment, and share
  const metrics = [
    { label: 'Page Views', current: '182.4k', previous: '195.8k', change: '-14%' },
    { label: 'Leads', current: '200', previous: '150', change: '+33%' },
    { label: 'Returning Visitors', current: '100', previous: '90', change: '+11%' },
    { label: 'Conversion Rate', current: '14%', previous: '12%', change: '+2%' },
    { label: 'Cost per Lead', current: '$50', previous: '$60', change: '-17%' },
    { label: 'Click-Through Rate', current: '2%', previous: '1.5%', change: '+0.5%' },
    { label: 'Customer Acquisition Cost', current: '$200', previous: '$250', change: '-20%' },
  ];

  // State to handle like, comment, and share for each metric
  const [metricState, setMetricState] = useState(
    metrics.map((metric) => ({
      liked: false,
      likeCount: 5,
      showComment: false,
    }))
  );

  // Handle Like button functionality for each metric
  const handleLike = (index) => {
    const updatedState = [...metricState];
    updatedState[index].liked = !updatedState[index].liked;
    updatedState[index].likeCount = updatedState[index].liked
      ? updatedState[index].likeCount + 1
      : updatedState[index].likeCount - 1;
    setMetricState(updatedState);
  };

  // Handle Comment button functionality for each metric
  const handleComment = (index) => {
    const updatedState = [...metricState];
    updatedState[index].showComment = !updatedState[index].showComment;
    setMetricState(updatedState);
  };

  // Handle Share button functionality for each metric
  const handleShare = (index) => {
    const shareUrl = window.location.href; // Get the current page URL
    const shareText = `Check out the stats for ${metrics[index].label}`; // Customize share message
    window.open(
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareText)}`,
      '_blank'
    );
  };

  return (
    <div className="flex flex-col min-h-screen m-8 px-4 sm:px-6 lg:px-8">
      <h2 className="text-2xl font-bold m-4 font-bold">Customer Experience</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 rounded border px-4 py-4">
        {metrics.map((metric, index) => (
          <div key={index} className="bg-white rounded-lg shadow-lg flex flex-col h-full relative">
            <div className="flex-grow m-4 p-4">
              <h3 className="text-xl font-semibold m-2">{metric.label}</h3>
              <p className="text-2xl font-bold">{metric.current}</p>
              <p className={`text-sm ${metric.change.startsWith('-') ? 'text-red-500' : 'text-green-500'}`}>
                {metric.change} {metric.previous ? ` (Previous: ${metric.previous})` : ''}
              </p>
            </div>
            {/* Footer with icons */}
            <div className="flex justify-between items-center bg-[#FD5F20] p-4 mt-4 rounded-b-lg space-x-4">
              {/* Like Button */}
              <button className="group p-2 hover:text-blue-500" onClick={() => handleLike(index)}>
                <span className="inline-flex items-center justify-center w-10 h-10 rounded-full">
                  <FontAwesomeIcon
                    icon={faHeart}
                    className={`text-lg ${metricState[index].liked ? 'text-red-500' : 'text-white'}`}
                  />
                </span>
                <span className="text-white text-sm">{metricState[index].likeCount} Likes</span>
              </button>

              {/* Comment Button */}
              <button className="group p-2 hover:text-blue-500" onClick={() => handleComment(index)}>
                <span className="inline-flex items-center justify-center w-10 h-10 rounded-full">
                  <FontAwesomeIcon icon={faComment} className="text-white text-lg" />
                </span>
              </button>

              {/* Share Button */}
              <button className="group p-2 hover:text-blue-500" onClick={() => handleShare(index)}>
                <span className="inline-flex items-center justify-center w-10 h-10 rounded-full">
                  <FontAwesomeIcon icon={faShareAlt} className="text-white text-lg" />
                </span>
              </button>
            </div>

            {/* Modal - Comment Section (shown if the user clicks Comment) */}
            {metricState[index].showComment && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white p-6 rounded-lg max-w-md w-full">
                  <h3 className="text-lg font-semibold mb-4">Write a Comment</h3>
                  <textarea
                    className="w-full h-24 p-2 border border-gray-300 rounded-md"
                    placeholder="Write a comment..."
                  ></textarea>
                  <div className="mt-4 flex justify-between">
                    <button
                      className="bg-[#FD5F20] text-white px-4 py-2 rounded-lg"
                      onClick={() => handleComment(index)} // Close modal
                    >
                      Cancel
                    </button>
                    <button className="bg-[#FD5F20] text-white px-4 py-2 rounded-lg">
                      Post Comment
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatsticDashboard;

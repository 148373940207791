import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fot from '../assets/img/Mask group (5).png'
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn, faWhatsapp, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
const Footer = () => {
  return (
    <footer className="bg-[#FD5F20] bg-opacity-60 text-black py-12 mt-auto font-times ">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-2xl text-gray-800 font-bold mb-4">About Us</h3>
            <p className="text-gray-800">
              Ato Creatives is a creative marketing agency dedicated to helping startups succeed online.
            </p>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-4 text-gray-800">Contact Us</h3>
            <p className="text-gray-800">
              <span className="flex items-center mb-2">
                <FontAwesomeIcon icon={faEnvelope} className="mr-2 text-gray-800" />
                Email:
                <a href="mailto:info@atocreative.et" className="text-indigo-500 hover:underline ml-1">info@atocreative.et</a>
              </span>
              <span className="flex items-center mb-2">
                <FontAwesomeIcon icon={faPhone} className="mr-2 text-gray-800" />
                Phone:
                <a href="tel:+251925401353" className="text-indigo-500 hover:underline ml-1">+251925401353</a>
              </span>
              <span className="flex items-center">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2 text-gray-800" />
                Address: 123 Street, Addis Ababa, Ethiopia
              </span>
            </p>
          </div>

          <div className="flex flex-col items-center">
          {/* <div className="mb-4 w-full">

    <img
      src={fot} // replace with your image source
      alt="Ato Creative"
      className="w-full h-40" // Set image to full width and maintain aspect ratio
    />
  </div> */}

            <h3 className="text-2xl font-semibold mb-4 text-gray-800">Follow Us</h3>

            <div className="flex flex-wrap justify-center space-x-4 text-gray-800">
              <a href="https://www.facebook.com/profile.php?id=61561831803941" className="text-3xl text-gray-800 hover:text-white mb-2">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a href="#" className="text-3xl text-gray-800 hover:text-white mb-2">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a href="https://www.instagram.com/atocreative_et" className="text-3xl text-gray-800 hover:text-white mb-2">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a href="#" className="text-3xl text-gray-800 hover:text-white mb-2">
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
              <a href="https://www.tiktok.com/@atocreative_et" className="text-3xl text-gray-800 hover:text-white mb-2">
                <FontAwesomeIcon icon={faTiktok} />
              </a>
              <a href="https://youtube.com/@atocreative_et" className="text-3xl text-gray-800 hover:text-white mb-2">
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            </div>
          </div>


        </div>
        <div className="mt-8 text-center text-lg">
          <p>&copy; {new Date().getFullYear()} Ato Creatives Agency. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

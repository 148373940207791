import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';
import HeadComponent from '../../head';
import { api } from '../../api';
import AlertModal from './AllertModal'
const ContactForm = () => {
  
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    phone: '',
    budget: '',
    company: '',
    metrics: '',
  });
  const [responseMessage, setResponseMessage] = useState('');
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [modalVisible, setModalVisible] = useState(false);

  const [isSuccess, setIsSuccess] = useState(false);
  useEffect(() => {
    api.get('/contacts/CreateContact')
      .then(response => setData(response.data))
      .catch(error => console.log(error));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/contacts/CreateContact', formData);
      setResponseMessage("Your message was sent successfully!"); // Success message
      setIsSuccess(true);
      setModalVisible(true);
      setFormData({ name: '', email: '', phone: '', budget: '', company: '', metrics: '', message: '' }); // Reset form
    } catch (error) {
      setResponseMessage(error.response?.data?.error || 'An error occurred while sending your message.'); // Error message
      setIsSuccess(false);
      setModalVisible(true);
    }
  };
  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <>
   <HeadComponent/>
    <div className="max-w-full min-h-[calc(100vh-5rem)] mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex flex-col lg:flex-row lg:space-x-8">
        <div className='flex flex-col flex-1 mb-8 lg:mb-0'>
          <h1 className='text-3xl sm:text-4xl lg:text-5xl font-bold mb-4'>
            Would You Like To Start A Project With Us?
          </h1>
          <p className='text-base sm:text-lg mb-4'>
            Uncover and define the fundamental qualities and values of your brand with the guidance of our team of experts. Our seasoned professionals will work closely with you to reveal the true essence of your brand, allowing you to better understand and communicate its unique identity to your audience. Let us help you unlock the full potential of your brand with our comprehensive discovery process.
          </p>
          <div className="pt-5 font-bold">
            <p>
              +251 11 554 4901 / info@atocreative.et<br />
              Bedesta Building, 4th floor, Addis Ababa, Ethiopia<br />
              Copyright ©2024 ato creative
            </p>
          </div>
        </div>

        <div className='flex flex-col flex-1'>
          <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
          {/* {responseMessage && <p className="mb-4 text-green-500">{responseMessage}</p>} */}
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="appearance-none border border-2 border-[#FD5F20] rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  className="appearance-none border border-2 border-[#FD5F20] rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <input
                  type="number"
                  step={2}
                  id="budget"
                  name="budget"
                  className="appearance-none border border-2 border-[#FD5F20] rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Budget"
                  value={formData.budget}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <input
                  type="text"
                  id="company"
                  name="company"
                  className="appearance-none border border-2 border-[#FD5F20] rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Company"
                  value={formData.company}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="appearance-none border border-2 border-[#FD5F20] rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <input
                  type="text"
                  id="metrics"
                  name="metrics"
                  className="appearance-none border border-2 border-[#FD5F20] rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Preferred Contact Metrics"
                  value={formData.metrics}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div>
              <textarea
                id="message"
                name="message"
                className="appearance-none border border-2 border-[#FD5F20] rounded-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-32 resize-none"
                placeholder="Your Message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </div>
            <div className="flex items-center justify-center">
              <button
                type="submit"
                className="bg-[#FD5F20] hover:bg-[#FD5F20] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full sm:w-auto"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      {modalVisible && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className={`bg-white rounded-lg shadow-lg p-6 w-full max-w-lg`}>
            <h2 className={`text-xl font-bold mb-4 ${isSuccess ? 'text-green-600' : 'text-red-600'}`}>
              {isSuccess ? 'Success' : 'Error'}
            </h2>
            <p className={`mb-4 ${isSuccess ? 'text-green-600' : 'text-red-600'} {isSuccess ? 'Success' : 'Error'`}>{responseMessage}</p>
            <button
              onClick={closeModal}
              className="bg-[#FD5F20] text-white font-bold py-2 px-4 rounded hover:bg-[#e4541d]"
            >
              Close
            </button>
          </div>
        </div>
      )}
      <div className="mt-8">
        <h3 className="text-2xl font-semibold mb-4 text-center">Find Us</h3>
        <div className="w-full">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13255.060213575276!2d38.79133532530632!3d8.99486305900315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b8584b0f1294d%3A0x78c5c25e5067a736!2sBole%20Medhanialem%20Church!5e0!3m2!1sen!2set!4v1722943168146!5m2!1sen!2set" // Replace with your actual embed code
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="rounded-lg shadow-lg w-full h-60"
          ></iframe>
        </div>
      </div>
    </div>
    </>
  );
};

export default ContactForm;

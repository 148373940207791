import React from 'react';
import map from '../../assets/img/Map.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';

const Connect = () => {
    return (
        <div className='min-h-full px-4 sm:px-6 lg:px-8 pt-20'>
            <div className='mt-8 flex flex-col md:flex-row'> {/* Use flex-row for medium and larger screens */}
                <div className='flex-1 md:flex-none md:w-1/2 p-4'> {/* Text section */}
                    <h1 className='text-gray-800 text-2xl font-extrabold'>
                        Our office is located in the Beautiful City of Addis Ababa
                    </h1>
                    <p className='mt-4 text-lg'>
                        We like our home like we like our designs; clean and minimal. We’re based in the heart of beautiful Addis Ababa. Don’t hesitate to visit. We have an open door policy.
                    </p>
                    <button className=' rounded bg-[#FD5F20] m-4  px-6 py-3 font-semibold text-gray-200'>
                    <FontAwesomeIcon icon={faMapMarkedAlt} className="text-red-800 text-2xl" />      Use This Live Location to Our Office
                    </button>
                </div>

                <div className='flex-1 md:flex-none md:w-1/2 p-4'> {/* Map section */}
                    <img src={map} alt="Map Location" className='w-full h-auto' /> {/* Makes the image responsive */}
                </div>
            </div>
        </div>
    );
}

export default Connect;
